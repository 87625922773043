import React from 'react'
import Button2 from './Button2';
function Card2 (props)  {
  return (
    <div className='card'>
        <div className='card-body'>
            <img src={props.image} alt='card2' className="image"/>
            <h1 className='card-title'>{props.title}</h1>
            <h5 className='card-description'>{props.text}</h5>            
             <h3 className='card-description'>{props.status}</h3>
             <h3 className='card-description'>{props.dimenzije}</h3>
             <h3 className='card-description'>{props.tezina}</h3>
             <h3 className='card-description'>{props.nosivost}</h3>
        </div>
      <Button2></Button2>
    </div>
  )
}

export default Card2;