import React from 'react';
import "./SrednjiDeo.css";
import nova1 from "../images/nova1.jpg"
import jednokratna1 from "../images/jednokratne1.jpg"
import siroka1 from "../images/siroke1.jpg"
import { motion } from "framer-motion"
function SrednjiDeo() {
  return (
    <div>
      <div class="row3">
  <motion.div
    initial={{ y: 25, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
      delay: 0.2,
      duration: 0.4,
    }}
  class="column">
    <img className='slika' src={nova1} alt="Snow" />
  </motion.div>
  <motion.div
    initial={{ y: 25, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
      delay: 0.4,
      duration: 0.4,
    }}
   class="column">
    <img className='slika' src={jednokratna1} alt="Forest" />
  </motion.div>
  <motion.div
    initial={{ y: 25, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
      delay: 0.6,
      duration: 0.4,
    }}
   class="column">
    <img className='slika' src={siroka1} alt="Mountains" />
  </motion.div>
</div>
    </div>
  )
}

export default SrednjiDeo;