import React from 'react';
import { Link } from 'react-router-dom';
import './ButtonPocetna.css';
import { motion } from "framer-motion"
function ButtonPocetna() {
   
  return (
    <motion.div
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
      delay: 0.3,
      duration: 0.7,
    }} 
    >
      <Link to='/Proizvodi'>
      <button className='ButtonPocetna'>
         <p>Pogledajte naše proizvode</p>
      </button>
      </Link>
    </motion.div>
  )
}

export default ButtonPocetna;
