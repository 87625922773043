import React from 'react'
import "./GoogleMap.css";
import { motion } from "framer-motion"
function GoogleMap2() {
  return (
    <motion.div 
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
        delay:0.2,
      duration: 0.6,
    }} 
    >
        <div className='ObeMape'>
         <div className="GoogleMaps">
         <div className="contentWrapper3">
            <div className="content3">
                <span className="textWrapper3">
                    <span></span>Sedište firme:
                </span>
                <p>Auto Put br 53v, Novi Beograd, Beograd 11070</p>
        </div>
        </div>
         <iframe title='prvamapa'  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2829.
     445529886817!2d20.32849791580466!3d44.83285938336701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.
     1!3m3!1m2!1s0x475a661ec4eed36d%3A0xa2199a3b2367315a!2sPASULJ%20TRADE%20doo!5e0!3m2!1ssr!2srs!
     4v1670938309038!5m2!1ssr!2srs"></iframe>
          
        </div>
        <div className="GoogleMaps">
        <div className="contentWrapper3">
            <div className="content3">
                <span className="textWrapper3">
                    <span></span>Prodajni centar:
                </span>
                <p>Sačka 15 (ulaz sa novosadskog autoputa)</p>
        </div>
        </div>
        <iframe title='drugamapa'  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2827.075199676312!2d20.
       314253915805843!3d44.881118580179866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a678757202403%3A0x70a2bfb7994b5021!
       2sJovic%20Group%202020%20DOO!5e0!3m2!1ssr!2srs!4v1675793221556!5m2!1ssr!2srs"></iframe>
          
        </div>
          
     
       </div>
    </motion.div>
  )
}

export default GoogleMap2;