import React, { useRef } from 'react';
import { motion } from "framer-motion"
import emailjs from '@emailjs/browser';
import { ImLocation2 } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { BsFillBookFill } from 'react-icons/bs';
import "./Contact.css";
function Contact() {
 
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      
      emailjs.sendForm('service_xr7kylx', 'template_x64c2fr', form.current, 'aLjro8nWt6-VZy32u')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    };
    return (
      <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        delay: 0.2,
        duration: 0.6,
      }}
      class="container">
      <span class="big-circle"></span>
      <div class="form">
        <div class="contact-info">
          <h3 class="title">Kontaktirajte nas</h3>
          <p class="text">
           Pallet Center by JovicGroup je kompanija koja se bavi prodajom novih i polovnih drvenih paleta.
           U našem asortimanu proizvoda imamo sve standardne tipove drvenih paleta, a
            pravimo i drvene palete po želji kupaca.
          </p>

          <div class="info">
            <div class="information">
              <ImLocation2 className='icon'/>
              <p>Adresa: Beograd-Autoput 53v-Novi Beograd, Srbija</p>
            </div>
            <div class="information">
              <MdEmail className='icon'/>
              <p>E-mail: jovicgroup2020@gmail.com</p>
            </div>
            <div class="information">
            <BsFillTelephoneFill className='icon'/>
              <p>Tel: +381649054648</p>
            </div>
            <div class="information">
            <BsFillBookFill className='icon'/>
              <p>Pib: 111796054</p>
            </div>
          </div>
        </div>

        <div class="contact-form">
          <span class="circle one"></span>
          <span class="circle two"></span>

          <form ref={form} onSubmit={sendEmail} >
            <h3 className="title">Kontaktirajte nas</h3>
            <div className="input-container">
              <input type="text" name="from_name" placeholder='Ime' className="input" />
            </div>
            <div class="input-container">
              <input type="email" name="Email" placeholder='E-mail' class="input" />
            </div>
            <div class="input-container">
              <input type="tel" name="Broj_Telefona" placeholder='Broj telefona' class="input" />
            </div>
            <div class="input-container textarea">
              <textarea name="Poruka" placeholder='Poruka' class="input"></textarea>
            </div>

            <input type="submit" value="Pošaljite" class="btn" />
          </form>
        </div>
      </div>
    </motion.div>

  )
}

export default Contact;
