import "./Palete.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import nova1 from "../images/nova1.jpg"
import nova2 from "../images/nova2.jpg"
import nova3 from "../images/nova3.jpg"
import { useEffect } from "react";

function NoveEuroPalete() {
   
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

    return (
  <div>
   <Navbar/>
   <container>
        <div className="NovaEuro">
        <img src={nova1} alt="paleta 1"/>
          <div class="contentWrapper2">
            <div class="content2">
                <span class="textWrapper2">
                    <span></span>Specifikacije
                </span>
                <p>Alternativne oznake: EURO- palete, euro palete, ravne palete, pul palete, 
zamenljive palete,... <br/>
Kategorija: Četvoroulazne ravne palete<br/>
Sopstvena težina: 20 - 25 kg (u zavisnosti od vlažnosti palete)<br/>
2 ivične daske (1200 x 145 x 22)<br/>
2 doivične daske (1200 x 100 x 22)<br/>
1 srednja daska (1200 x 145 x 22)<br/>
3 poprečne daske (800 x 145 x 22)<br/>
2 donje ivične daske (1200 x 100 x 22)<br/>
1 donja srednja daska(1200 x 145 x 22)<br/>
3 drvene kocke (145 x 145 x 78)<br/>
6 drvenih kocki (145 x 100 x 78)<br/>
78 eksera</p>
        </div>
        </div>
        </div>
        <div className="NovaEuro">
        <img src={nova2} alt="paleta 2"/>
          <div class="contentWrapper2">
            <div class="content2">
                <span class="textWrapper2">
                    <span></span>Osobine
                </span>
                <p>Svi ekseri, koji se koriste za spajanje EURO-palete, obeleženi su oznakom na glavi.<br/>
Oznaka se obično sastoji od dva slova.<br/>
Isto tako se na drvenim kockama obeju dužih strana palete nalaze tri pečata.<br/>
Ukoliko je paleta tretirana IPPC procedurom, to se može na uočiti na srednjoj nožici, 
koja je dodatno na levoj strani obeležena simbolom u obliku klasa žita i vertikalnim 
natpisom IPPC.
</p>
        </div>
        </div>
        </div>
        <div className="NovaEuro">
        <img src={nova3} alt="paleta 3"/>
          <div class="contentWrapper2">
            <div class="content2">
                <span class="textWrapper2">
                    <span></span>Toplotni tretman (IPPC Norma: ISPM 15)
                </span>
                <p>Tokom toplotnog tretmana se jezgro drveta 30 minuta zagreva najmanje na 56°C, 
kako bi se otklonili potencijalni paraziti ili štetočine.<br/> Ove palete su označene dodatnim 
pečatom (pogledati gore).<br/><br/>

AT     ISO- kod za Austriju <br/><br/>

XX     zvanična overena oznaka od strane nadležnih organa za izdavanje dozvola<br/><br/>

000    Registarski broj preduzeća, koje je proizvelo ili tretiralo drvo korišćeno za 
       drvenu ambalažu.<br/><br/>

DB     „Debarked“ = ,,oljušteno", skinuta kora<br/><br/>

HT     Primenjeni tretman: Heat Treatment (jezgro drveta na 56°C najmanje 30 minuta)<br/><br/>

</p>
        </div>
        </div>
        </div>
   </container>
   <Footer/>
  </div>
  )
}

export default NoveEuroPalete;