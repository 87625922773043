import "./App.css";
import { Route, Redirect } from "react-router-dom";
import Pocetna from "./pages/Pocetna";
import Proizvodi from "./pages/Proizvodi";
import Onama from "./pages/O-nama";
import Kontakt from "./pages/Kontakt";
import NoveEuroPalete from "./OpsirnijeStrane/NoveEuroPalete";
import PolovnaSvetlaEuro from './OpsirnijeStrane/PolovnaSvetlaEuro'
import PolovnaTamnaEuro from "./OpsirnijeStrane/PolovnaTamnaEuro";
import JednokratnePalete from "./OpsirnijeStrane/JednokratnePalete";
import SirokePalete from "./OpsirnijeStrane/SirokePalete";

function App() {
  return (
    <div className="App">
   <Route path="/" exact>
    <Redirect to='/Pocetna'/>
   </Route>
 <Route path="/Pocetna">
     <Pocetna/>
   </Route>
   <Route path="/proizvodi">
     <Proizvodi/>
   </Route>
   <Route path="/o-nama">
     <Onama/>
   </Route>
   <Route path="/kontakt">
     <Kontakt/>
   </Route>
   <Route path='/nove-euro-palete'>
     <NoveEuroPalete/>
   </Route>
   <Route path='/euro-paleta-svetla'>
     <PolovnaSvetlaEuro/>
   </Route>
   <Route path='/euro-paleta-tamna'>
     <PolovnaTamnaEuro/>
   </Route>
   <Route path='/jednokratne-palete'>
     <JednokratnePalete/>
   </Route>
   <Route path='/siroke-palete'>
     <SirokePalete/>
   </Route>
</div> 
  );
  
}

export default App;