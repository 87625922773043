import React from 'react'
import { useEffect } from 'react';
import Navbar from '../components/Navbar'
import Contact from '../components/Contact';
import GoogleMap from '../components/GoogleMap'
import Footer from '../components/Footer';

function Kontakt() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <div>
      <Navbar/>
      <Contact/>
      <GoogleMap/>
      <Footer/>
    </div>
  )
}

export default Kontakt
