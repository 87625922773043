import React from 'react'
import Button4 from './Button4';
function Card4 (props)  {
  return (
    <div className='card'>
        <div className='card-body'>
            <img src={props.image} alt='card4' className="image"/>
             <h1 className='card-title'>{props.title}</h1>
             <h5 className='card-description'>{props.text}</h5>
             <h3 className='card-description'>{props.status}</h3>
             <h3 className='card-description'>{props.dimenzije}</h3>
             <h3 className='card-description'>{props.tezina}</h3>
             <h3 className='card-description'>{props.nosivost}</h3>
        </div>
      <Button4></Button4>
    </div>
  )
}

export default Card4;