import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";


function Footer() {
  return (
    <section className="Footer">
      <div className="container2">
        <div className="row">
          <div className="footer-col">
            <h4>Stranice</h4>
            <ul>
              <li><Link to='/pocetna'>Početna</Link></li>          
              <li><Link to='/proizvodi'>Proizvodi</Link> </li>          
              <li><Link to='/o-nama'>O nama</Link></li>
              <li><Link to='/kontakt'>Kontakt</Link></li>  
      </ul>
      </div>
      <div className="footer-col">
            <h4>Proizvodi</h4>
            <ul>
              <li><Link to='/nove-euro-palete'>Euro palete</Link></li>          
              <li><Link to='/jednokratne-palete'>Jednokratne palete</Link> </li>          
              <li><Link to='/siroke-palete'>Široke palete</Link></li>
              
      </ul>
      </div>
      <div className="footer-col">
            <h4>Kontakt</h4>
            <ul>
              <li>Jovic Group 2020 doo</li>
              <li>Pib: 111796054</li>          
              <li>Adresa: Beograd-Autoput 53v-Novi Beograd, Srbija</li>          
              <li>E-mail: jovicgroup2020@gmail.com</li>
              <li><a href="tel:+381649054648">Tel: +381649054648</a></li>  
              <li>Kontakt osoba: Djordje Jović</li>
      </ul>
      </div>
      <div className="footer-col">
            <h4>Copyright</h4>
            <ul>
              <li>@JovicGroup</li>  
      </ul>
      </div>
      </div>
      </div>
    </section>
  )
}

export default Footer;
