import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';

function Button4() {
   
  return (
    <div>
      <Link to='/euro-paleta-svetla'>
      <button className='Button'>
         Opširnije
      </button>
      </Link>
    </div>
  )
}

export default Button4;
