import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HttpsRedirect>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </HttpsRedirect>

);
