import Prva from "../images/testslika.jpg";
import Druga from "../images/testslika2.jpg";
import Treca from "../images/testslika3.jpg";
import Cetvrta from "../images/testslika4.jpg";
import Peta from "../images/testslika5.jpg";
import Sesta from "../images/testslika6.jpg";

const Objekat = [
    {
      urls: Prva,
    },
    {
      urls: Druga,
    },
    {
      urls: Treca,
    },
    {
      urls: Cetvrta,
    },
    {
      urls: Peta,
    },
    {
      urls: Sesta,
    },

];

export default Objekat;