import React from 'react'
import { useEffect } from 'react';
import Navbar from '../components/Navbar';
import OnamaSadrzaj from '../components/Onama';
import Footer from '../components/Footer';
function Onama() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
<div>
      <Navbar/>
      <OnamaSadrzaj/>
      <Footer />
    </div>
  )
}

export default Onama;
