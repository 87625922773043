import React from 'react'
import './Onama.css'
import { motion } from "framer-motion"
import slikaonama from '../images/coverslikaonama.jpeg';
function Onama() {
  return (
    <motion.div 
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
        delay:0.2,
      duration: 0.6,
    }} 
    
    className="row2">
        <div class="imgWrapper">
            <img src={slikaonama} alt=""/>
        </div>
        <div class="contentWrapper">
            <div class="content">
                <span class="textWrapper">
                    <span></span>O nama
                </span>
                <p>Jovic Group 2020 doo Beograd je mlada i ambiciozna kompanija koja se bavi
                   otkupom i prodajom novih i korišćenih drvenih paleta.
                    Firma je osnovana 20.12.2019. godine sa PIB-om 111796054 i matičnim brojem 21541966.
                     Sedište firme se nalazi na adresi Autoput 53v, Beograd,
                      a prodajni centar se nalazi na Novosadskom autoputu.
Kompanija Jovic Group 2020 doo Beograd se razlikuje od drugih kompanija u ovom
 sektoru po svom visokom kvalitetu proizvoda i usluga.
  Njihovi stručni timovi su uvek spremni da pomognu svakom kupcu da pronađe
   najbolje rešenje za njegove potrebe. Drvene palete su ključni element
    u transportu i skladištenju različitih vrsta robe, zbog toga je važno da budu kvalitetne i pouzdane.
Firma se trudi da pruži svojim kupcima što bolje usluge,
 kao i da prati sve trendove u industriji drvenih paleta.
 Naš cilj je da postanemo lideri na tržištu drvenih paleta u Srbiji.
Jovic Group 2020 doo Beograd se trudi da pruži kvalitetne proizvode
 i usluge svojim kupcima i partnerima. Osim kupovine i prodaje drvenih paleta,
  firma pruža i usluge popravke i renoviranja paleta, kako bi se što više produžilo njihovo korišćenje.
   Firma takođe pruža i uslugu dostave paleta do kupca, kako bi se olakšalo poslovanje.
    Ukoliko tražite pouzdane i kvalitetne drvene palete, kontaktirajte Jovic Group 2020 doo Beograd,
     i oni će rado da Vam pomognu.
</p>
                
            </div>
        </div>
    </motion.div>
  )
}

export default Onama;
