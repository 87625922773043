import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';
function Button3() {
   
  return (
    <div>
      <Link to='/siroke-palete'>
      <button className='Button'>
         Opširnije
      </button>
      </Link>
    </div>
  )
}

export default Button3;