import "./Palete.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import jednokratna1 from "../images/jednokratne1.jpg"
import jednokratna2 from "../images/jednokratna2.jpg"
import jednokratna3 from "../images/jednokratne3.jpg"
import { useEffect } from "react";

function JednokratnePalete() {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div>
      <Navbar/>
      <container>
        <div className="NovaEuro">
        <img src={jednokratna1} alt="paleta 1"/>
          <div class="contentWrapper2">
            <div class="content2">
                <span class="textWrapper2">
                    <span></span>Specifikacije
                </span>
                <p>Kategorija: Četvoroulazne ravne palete<br/>
Sopstvena težina: 15 kg<br/>

Paleta 800x1200x123 jednokratna:<br/>

Gornje daske 5 komada 1200x75x15<br/>
Medjudaske 3 komada 800x75x15<br/>
Kocke 9 komada 75x75x75<br/>
Donje daske 1200x75x15<br/>

Paleta 800x1200x123 jednokratna:<br/>

Gornje daske 7 komada 1200x75x15<br/>
Medjudaske 3 komada 800x75x15<br/>
Kocke 9 komada 75x75x75<br/>
Donje daske 1200x75x15<br/>

Paleta 800x600x123 jednokratna:<br/>

Gornje daske 5 komada 600x75x15<br/>
Medjudaske 2 komada 800x75x15<br/>
Kocke 6 komada 75x75x75<br/>
Donje daske 600x75x15<br/>
</p>
        </div>
        </div>
        </div>
        <div className="NovaEuro">
        <img src={jednokratna2} alt="paleta 2"/>
          <div class="contentWrapper2">
            <div class="content2">
                <span class="textWrapper2">
                    <span></span>Osobine
                </span>
                <p>Ukoliko je paleta tretirana IPPC procedurom, to se može na uočiti na srednjoj nožici, 
koja je dodatno na levoj strani obeležena simbolom u obliku klasa žita i vertikalnim 
natpisom IPPC.
</p>
        </div>
        </div>
        </div>
        <div className="NovaEuro">
        <img src={jednokratna3} alt="paleta 3"/>
          <div class="contentWrapper2">
            <div class="content2">
                <span class="textWrapper2">
                    <span></span>Toplotni tretman (IPPC Norma: ISPM 15)
                </span>
                <p>Tokom toplotnog tretmana se jezgro drveta 30 minuta zagreva najmanje na 56°C, 
kako bi se otklonili potencijalni paraziti ili štetočine.<br/> Ove palete su označene dodatnim 
pečatom (pogledati gore).
</p>
        </div>
        </div>
        </div>
   </container>
      <Footer/>
        
    </div>
  )
}

export default JednokratnePalete
