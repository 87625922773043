import React from 'react'
import { useEffect } from 'react';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import AsortimanProizvoda from '../components/AsortimanProizvoda';

function Proizvodi() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
<div>
      <Navbar/>
      <AsortimanProizvoda/>
      <Footer />
    </div>
  )
}

export default Proizvodi
