import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import "./Navbar.css";
import { motion } from "framer-motion"
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  isOpen?document.body.style.overflow = "hidden":document.body.style.overflow = "auto";
  return (
    
    <motion.div
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
      duration: 0.6,
    }}
     className="Navbar">
      <NavLink className="nav-logo" to='/pocetna'>JovicGroup</NavLink>
      <div className={`nav-items ${isOpen && "open"}`}>
             
             <NavLink activeClassName='active' className='pocetna'to='/pocetna'>Početna</NavLink>           
             <NavLink activeClassName='active' className='proizvodi'to='/proizvodi'>Proizvodi</NavLink>           
             <NavLink activeClassName='active' className='onama'to='/o-nama'>O nama</NavLink>
             <NavLink activeClassName='active' className='kontakt' to='/kontakt'>Kontakt</NavLink>         
      </div>
      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </motion.div>
  );
};

export default Navbar;
