import React from 'react';
import { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import ButtonPocetna from '../components/ButtonPocetna';
import SrednjiDeo from '../components/SrednjiDeo';

function Pocetna() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
<div>
      <Navbar/>
      <Slider/>
      <SrednjiDeo/>
      <ButtonPocetna/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default Pocetna;
