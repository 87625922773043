import React from 'react'
import "./AsortimanProizvoda.css"
import { motion } from "framer-motion"
import slika from '../images/nova-eu-01.jpg';
import slika2 from '../images/bela-eu-01.jpg'
import slika3 from '../images/tamna-eu-01.jpg'
import slika4 from '../images/nova-jednokratna-01.jpg'
import slika5 from '../images/tamna jedno-01.jpg'
import slika6 from '../images/sirokanovamarko-01.jpg'
import slika7 from '../images/siroka-polovnakakotreba-01.jpg'
import Card from './Card.js';
import Card2 from './Card2';
import Card3 from './Card3';
import Card4 from './Card4';
import Card5 from './Card5';
function AsortimanProizvoda() {
  return (
    <motion.div 
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
      delay:0.2,
      duration: 0.6,
    }}
    >
       <h1 className='naslovi'>EURO PALETE</h1> 
           <div className='wrapper'>
            <Card
              image= {slika}
              text= "Euro Paleta je paleta od punog drveta visokog kvaliteta.Svaku paletu prati žig o termo tretiranju, IPPC sertifikat. Dimenzije Euro paleta su standardno 1200 x 800 x 144mm i mora da se sastoje od 11 dasaka, 9 blokova i tačno 78 specijalnih eksera."
              title="Euro paleta"
              status="Status: Nova"
                dimenzije="Dimenzije: 1200x800x144mm"
                tezina= "Težina: 25kg"
                nosivost= "Nosivost: 1500-4000kg"
            />
            </div>
            <div className='wrapper1'>
            <Card4
              image= {slika2}
              title="Euro paleta I klasa"
              text="EPAL/EUR palete koje su imale manji broj
              manipulacija u procesu proizvodnje, 
              skladištenja i transporta, ali koje u potpunosti 
              imaju očuvana osnovna mehanička svojstva i 
              nosivost nove EPAL palete."
                status="Status: Polovna-Svetla"
                dimenzije="Dimenzije: 1200x800x144mm"
                tezina= "Težina: 25kg"
                nosivost= "Nosivost: 1500-3500kg"
              
            />
            <Card5
              image= {slika3}
              title="Euro paleta II klasa"
              text="
              EPAL/EUR palete koje su imale veći broj 
              manipulacija u procesu proizvodnje, 
              skladištenja i transporta ili su usled dužeg 
              vremena stajanja vidljivo promenile estetska 
              svojstva."
                status="Status: Polovna-Tamna"
                dimenzije="Dimenzije: 1200x800x144mm"
                tezina= "Težina: 20-25kg"
                nosivost= "Nosivost: 1500-2500kg"
            />
            </div>
            <h1 className='naslovi'>JEDNOKRATNE PALETE</h1>
            <div className='wrapper2'>
            <Card2
            image= {slika4}
            title="Jednokratna paleta nova"
            text= "Jednokratna paleta je paleta od punog drveta visokog kvaliteta.Svaku paletu prati žig o termo tretiranju,IPPC sertifikat. Jednokratne drvene paletepravimo u raznim dimenzijama, kao na primer 1200x800 mm ili 1200x1000 mm. Pravimo drvene palete po specifikaciji kupaca."
                status = "Status: Nova"
                dimenzije="Dimenzije: 1200x800x123mm"
                tezina= "Težina: 15kg"
                nosivost= "Nosivost: 700-1000kg"
            />
            <Card2
            image= {slika5}
            title="Jednokratna paleta polovna" 
            text= "Pošto su u suštini predviđene za jednokratnu upotrebu, najčešće ih koriste preduzeća koja šalju svoje palete u zemlje van Evrope. Slabija stabilnost i manja nosivost predstavljaju mane ove vrste paleta. Prema želji kupca možemo da proizvodimo i specijalne palete."
                status = "Status: Polovna"
                dimenzije="Dimenzije: 1200x800x123mm"
                tezina= "Težina: 15kg"
                nosivost= "Nosivost: 700-1000kg"
            />
            </div>
            <h1 className='naslovi'>ŠIROKE PALETE</h1>
            <div className='wrapper3'>
            <Card3
            image= {slika6}
            title="Široka paleta nova"
            text= "Široka paleta je paleta od punog drveta visokog kvaliteta. Svaku paletu prati žig o termo tretiranju, IPPC sertifikat. Široke drvene palete pravimo u raznim dimenzijama, kao na primer 1200x1000 mm ili 1200x1200 mm. Pravimo drvene palete po specifikaciji kupaca. "
                status = "Status: Nova "
                dimenzije="Dimenzije: 1200x1000x144mm"
                tezina= "Težina: 20kg"
                nosivost= "Nosivost: 1250-2000kg"
            />
            <Card3
            image= {slika7}
            title="Široka paleta polovna"
            text="Široka paleta je paleta koja je bila više godina u upotrebi, samim tim je manjak kvaliteta nego nova paleta ali jednako dobra za upotrebu jer ima jednaku funkcionalnost kao nova ali manju nosivost. Svaku paletu prati žig o termo tretiranju, IPPC sertifikat. Široke drvene palete pravimo po specifikaciji kupaca u raznim dimenzijama, kao na primer 1200x1000 mm ili 1200x1200 mm."
                status = "Status: Polovna"
                dimenzije="Dimenzije: 1200x1000x144mm"
                tezina= "Težina: 20kg"
                nosivost= "Nosivost: 1250-2000kg" 
            />
            </div>
          </motion.div>
        )
      }




export default AsortimanProizvoda;
