import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';

function Button() {
   
  return (
    <div>
      <Link to='/nove-euro-palete'>
      <button className='Button'>
         Opširnije 
      </button>
      </Link>
    </div>
  )
}

export default Button;
